import { Button, Form, Input, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { get, post } from '../../Common/api'
import { Color } from '../../Common/Color'
import SlotContext from './SlotContext'
import SlotLayout from './SlotLayout'

export default function ConfirmSlotForm () {
  const { data } = useContext(SlotContext)
  const { id } = useParams()
  const [slot, setSlot] = useState()
  const { t } = useTranslation()
  const [form] = useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    get(`public/slots/${id}`, {}, (json) => {
      setSlot(json)
    })
  }, [id])

  const onFinish = (values) => {
    setLoading(true)
    post(`public/slots/${slot.id}/confirm`, values, () => {
      navigate(`/slots/${slot.id}`)
    }, () => {
      notification.error({ message: t('Public.ConfirmSlotForm.error'), placement: 'bottom' })
      setLoading(false)
    })
  }

  const onFinishFailed = () => {
    setLoading(false)
  }

  const resendCode = () => {
    post(`public/slots/${slot.id}/resend_confirmation_code`, {}, () => {
      notification.success({ message: t('Public.ConfirmSlotForm.resendSuccess'), placement: 'bottom' })
    })
  }

  const initialValues = {
    start_timestamp: data?.slotTime?.start,
    end_timestamp: data?.slotTime?.end,
    calendar_id: data?.calendarId,
    country: data?.country,
    civility: data?.civility,
    reason_id: data?.reasonId
  }

  return (
    <SlotLayout date={slot?.begin_date} box={slot?.box} title={t('Public.ConfirmSlotPage.title')} slot={slot}>
      <div className='confirmSlotFromDiv'>
        <Form
          style={{
            boxShadow: '0px 0px 2px 2px #f2f2f2',
            borderRadius: '1em',
            padding: '1em',
            textAlign: 'center',
            maxWidth: '270px',
            marginTop: '2px'
          }}
          id="ConfirmSlotForm"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
        >
          <Form.Item name='start_timestamp' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='end_timestamp' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='calendar_id' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='reason_id' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='country' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='civility' hidden>
            <Input />
          </Form.Item>
          <div style={{ fontWeight: 'bold', marginBottom: '1em' }}>
              {t('Public.ConfirmSlotForm.title')}
          </div>
          <Form.Item name="confirmation_code" style={{ width: '170px', margin: 'auto' }}
              rules={[{ required: true, message: t('rules.required') }]}>
              <Input onInput={e => { e.target.value = e.target.value.toUpperCase() }}
              style={{ textAlign: 'center', letterSpacing: '0.8em' }} placeholder="ERVKA" />
          </Form.Item>
          <a
              onClick={resendCode}
              style={{
                color: Color.red,
                textDecoration: 'underline',
                margin: '1em 0',
                display: 'block'
              }}>
              {t('Public.ConfirmSlotForm.resend')}
          </a>
          <Form.Item >
              <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                  {t('Public.ConfirmSlotForm.submitConfirmation')}
              </Button>
          </Form.Item>
        </Form >
      </div>
    </SlotLayout>
  )
}
