import { Empty } from 'antd'
import React from 'react'

import BoxCard from './BoxCard'

export default function BoxList ({ boxes, currentBox, setCurrentBox }) {
  const boxesList = boxes?.map((box) => <BoxCard currentBox={currentBox} setCurrentBox={setCurrentBox} key={box.id} box={box} />)
  const render = () => {
    if (boxes?.length > 0) {
      return boxesList
    } else {
      return <div style={{ textAlign: 'center', fontSize: '18px' }}>
                <Empty style={{ marginTop: '1em' }} description='' />
                <br />
                Contactez-nous pour ajouter une Box Médicale dans votre région<br/>
                <a href="mailto:contact@laboxmedicale.com">contact@laboxmedicale.com</a>
            </div>
    }
  }
  return (
        <div style={{ padding: '0.3em', overflow: 'auto' }}>
            <div className={'boxList'} style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                {render()}
            </div>
        </div>
  )
}
