import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button, Col, Popover, Row, Tag } from 'antd'
import { FormOutlined, PoweroffOutlined, WarningOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Info from '../../Common/Info'
import { Color } from '../../Common/Color'

const UnresolvedEvent = ({ events }) => {
  const navigate = useNavigate()

  const PopOverContent = ({ events }) => {
    return events.map(event => (
      <Row key={event.id} className='hoverable' style={{ width: '30em', cursor: 'pointer', '--hover-color': Color.red }}
        onClick={() => navigate(`/admin/events/${event.id}`)} >
        <Col span={6}>{dayjs(events.created_at).format('DD/MM/YYYY')}</Col>
        <Col span={12}>{event.title}</Col>
      </Row>
    ))
  }
  return (
    <Popover content={< PopOverContent events={events} />} title={t('Admin.BoxList.table.unresolvedEvents')}>
      <span style={{ width: '100%', display: 'block' }}>
        {events.length}
      </span>
    </Popover>

  )
}

const lastPing = (o) => {
  if (!o.last_ping) {
    return 'Jamais'
  }
  return dayjs(o.last_ping).format('DD/MM/YYYY HH:mm')
}

const stateMessage = (box) => {
  const messages = t(`Admin.Box.state.${box.state}`).split(' - ')
  return (
    <Tag color={t(`Admin.Box.stateColor.${box.state}`)}>
      {messages[0]}
      {messages[1] && <div>{messages[1]}</div>}
    </Tag>
  )
}

const displayEmptyFields = (fields) => (
  fields.map((field) => (<div key={field}>- {t(`Admin.BoxList.table.${field}`)}</div>))
)

const columns = (screens) => [
  {
    title: t('Admin.BoxList.table.name'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
    render: (_, o) => (<>
      { o.empty_fields.length > 0 &&
        <Popover content={<div>{displayEmptyFields(o.empty_fields) }</div>} >
          {o.name}
          <WarningOutlined style={{ color: 'orange', marginLeft: '3em' }} />
        </Popover>
      }
      { o.empty_fields.length === 0 && o.name }
    </>),
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: t('Admin.BoxList.table.zipCode'),
    dataIndex: 'zip_code',
    key: 'zipCode',
    width: 120,
    sorter: (a, b) => a.zip_code - b.zip_code,
    hidden: !screens.md
  },
  {
    title: t('Admin.BoxList.table.owner'),
    dataIndex: 'owner',
    key: 'owner',
    width: 200,
    render: (_, o) => o?.owner?.name,
    sorter: (a, b) => a?.owner?.name.localeCompare(b?.owner?.name),
    hidden: !screens.md
  },
  {
    width: 90,
    title: <Info text={t('Admin.BoxList.table.events')} infoText={t('Admin.BoxList.table.infoEvents')} />,
    dataIndex: 'unresolved_events',
    key: 'unresolved_events',
    render: (events) => (<UnresolvedEvent events={events} />),
    sorter: (a, b) => a.unresolved_events.length - b.unresolved_events.length,
    hidden: !screens.md
  },
  {
    width: 90,
    title: <Info text={t('Admin.BoxList.table.online')} infoText={t('Admin.BoxList.table.infoOnline')} />,
    dataIndex: 'online',
    key: 'online',
    align: 'center',
    render: (_, o) => (<Popover content={lastPing(o)} title={t('Admin.BoxList.table.lastInfoOnline')}>
      <span style={{ width: '100%', display: 'block' }}>
        {t(`Admin.BoxList.table.${o.active ? 'active' : 'inactive'}`)}
        <PoweroffOutlined style={{ color: o.is_online ? 'green' : 'red' }} />
      </span >
    </Popover>),
    sorter: (a, b) => a.is_online - b.is_online
  },
  {
    width: 150,
    title: t('Admin.BoxList.table.state'),
    dataIndex: 'state',
    key: 'state',
    render: (_, o) => o ? stateMessage(o) : '',
    sorter: (a, b) => a.state - b.state
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/boxes/${o.id}/calendar`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
].filter((col) => !col.hidden)

export default function BoxList ({ boxes }) {
  const rowClassName = (boxe) => {
    return boxe.newAlert ? 'new-alert' : ''
  }
  const screens = useBreakpoint()

  return (
    <CustTable
      rowClassName={rowClassName}
      height={'calc(100vh - 230px)'}
      initColumns={columns(screens)}
      dataSource={boxes.sort((a, b) => b.active - a.active)}
    />
  )
}
