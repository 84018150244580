import { Button, Input, Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { post } from '../../Common/api'
import { Color } from '../../Common/Color'
import {
  ExclamationCircleOutlined,
  StopOutlined
} from '@ant-design/icons'
const { confirm } = Modal

export default function ConfirmedSlot ({ slot, setSlot }) {
  const { t } = useTranslation()

  const onCancel = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: t('Public.ConfirmedSlot.modalConfirm'),
      onOk () {
        post(`public/slots/${slot.id}/cancel`, {}, (json) => {
          setSlot(json)
        })
      }
    })
  }

  return (
    <div>
      <div style={{
        boxShadow: '0px 0px 2px 2px #f2f2f2',
        borderRadius: '1em',
        padding: '1em',
        textAlign: 'center',
        maxWidth: '270px',
        marginTop: '2px'
      }}>
        {slot &&
          <>
            <div>
              {!slot.is_cancel && t('Public.ConfirmedSlot.description')}
            </div>
            {!slot.is_cancel &&
              <Input style={{ margin: '3em 0', color: Color.red, textAlign: 'center', letterSpacing: '0.5em', width: '170px', fontWeight: 'bold', fontSize: '1.5em' }}
                value={slot.digicode} disabled={true} />
            }
            {slot.is_cancel &&
              <>
                <StopOutlined style={{ fontSize: '32px', margin: '1em' }} />
                <div style={{ color: Color.red }}>{t('Public.ConfirmedSlot.canceled')}</div>
              </>
            }
            {!slot.is_cancel && <Button type="primary" htmlType="submit" onClick={() => onCancel()}>
              {t('Public.ConfirmedSlot.cancel')}
            </Button>}
          </>
        }
      </div>
    </div>
  )
}
