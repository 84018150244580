import React, { useEffect } from 'react'
import { useForm } from 'antd/es/form/Form'
import SlotForm from '../BoxPage/SlotForm'
import { Button, Col, notification, Popconfirm, Row } from 'antd'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { put } from '../../Common/api'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export default function AdminSlotForm ({ slot, cancel, back, setLoading }) {
  const [form] = useForm()

  const screens = useBreakpoint()
  useEffect(() => {
    const json = {
      ...slot,
      range: [dayjs(slot.begin_date), dayjs(slot.end_date)],
      appointment_date: dayjs(slot.appointment_date),
      start: dayjs(slot.start),
      created_at: dayjs(slot.created_at),
      phone: `+${slot.phone}`
    }
    form.setFieldsValue(json)
  }, [slot])

  const onFinish = (values) => {
    values = { ...values, phone: values.phone.slice(1) }
    saveSlot(values)
  }

  const saveSlot = (params) => {
    params = { ...params, begin_date: params.range[0], end_date: params.range[1] }
    setLoading(true)
    put(`admin/slots/${slot.id}`, params, () => {
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }
  const span = screens.xs ? 24 : 6

  return (
    <div style={{ position: 'relative' }}>
      <SlotForm form={form} edit={true} onFinish={onFinish} disabled={slot.is_cancel}
        specialist={slot.specialist} />
      <Row>
        <Col span={span} style={{ paddingBottom: '10px' }} >
          <Button type="primary" htmlType="submit" onClick={form.submit} disabled={slot.is_cancel}>
            {t('Admin.btn.submit')}
          </Button>
        </Col>
        <Col span={span}>
          <Popconfirm
            title={t('Admin.AdminSlotPage.cancelSlot')}
            onConfirm={cancel}
            okText={t('Admin.message.yes')}
            cancelText={t('Admin.message.no')}
            disabled={slot.is_cancel}
          >
            <Button style={{ marginLeft: '1em' }} danger disabled={slot.is_cancel}>
              {t('Admin.btn.cancel')}
            </Button>
          </Popconfirm>
        </Col>
        <Col span={span} style={{ position: 'absolute', right: '5px' }} >
          <Button type="primary" onClick={back}>
            {t('Admin.btn.back')}
          </Button>
        </Col>
      </Row>
    </div >
  )
}
