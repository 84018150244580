import { Layout, Modal, Spin } from 'antd'
import { t } from 'i18next'
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import SlotForm from '../SlotForm'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import AvailableNowCard from '../AvailableNowCard'
import CreateEventCard from '../../EventsPage/CreateEventCard'
import CreatePeriodCard from '../../PeriodPage/CreatePeriodCard'

dayjs.extend(weekday)
dayjs.extend(localeData)

export default function BoxCalendarPageXS ({
  box, slotModalVisible, loading, slotForm, setSlotModalVisible,
  onAdd, rangeDisable, boxAvailableNow, setRangeDisable, days,
  loadCalendarData, day, businessHours, events, renderEventContent, selected, minHour, maxHour
}) {
  const dayheader = (date) => {
    const weekDayDisplay = { 0: 'D', 1: 'L', 2: 'M', 3: 'M', 4: 'J', 5: 'V', 6: 'S' }
    return <div>
      <div>{weekDayDisplay[date.format('d')]}</div>
      <div>{date.format('D')}</div>
    </div>
  }
  return (
    <>
      {box && <>
        <Modal
          open={slotModalVisible}
          width="800px"
          title={t('Admin.BoxCalendar.popupTitle')}
          okText={t('actions.validate')}
          confirmLoading={loading}
          onOk={slotForm.submit}
          onCancel={() => setSlotModalVisible(false)}
          >
          <Spin spinning={loading}>
            <SlotForm form={slotForm} onFinish={onAdd} rangeDisable={rangeDisable} />
          </Spin>
        </Modal>
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '1em' }}>
            {
              boxAvailableNow && <AvailableNowCard setModalVisible={setSlotModalVisible} setRangeDisable={setRangeDisable} form={slotForm} minimized={true} />
            }
            <CreateEventCard box={box} minimized={true} />
            <CreatePeriodCard days={days} loadCalendarData={loadCalendarData} minimized={true} />
          </div>
          {
            box.id && <FullCalendar
              height="auto"
              viewClassNames='small-calendar'
              selectable={true}
              plugins={[timeGridPlugin, interactionPlugin]}
              headerToolbar={{ start: 'title', end: 'prev,next' }}
              firstDay={dayjs().day() - 1}
              initialDate={day || dayjs().format('YYYY-MM-DD')}
              initialView="timeGridWeek"
              dayHeaderContent={(args) => {
                return dayheader(dayjs(args.date))
              }}
              slotDuration="01:00:00"
              locale={frLocale}
              allDaySlot={false}
              displayEventTime={false}
              businessHours={businessHours(days)}
              events={events}
              eventContent={renderEventContent}
              datesSet={loadCalendarData}
              selectLongPressDelay={150}
              select={(info) => selected(info)}
              slotMinTime={minHour + ':00:00'}
              slotMaxTime={maxHour + ':00:00'}
            />
          }
        </Layout>
      </>
      }
    </>
  )
}
