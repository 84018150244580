import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { get } from '../../Common/api'
import CommonLogsPanel from '../Logs/LogsPanel'
import _ from 'lodash'
import { t } from 'i18next'

export default function LogsPanel ({ slot }) {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (!slot?.id) { return }
    getLogs()
  }, [slot])

  const getLogs = async () => {
    await get('admin/logs/slot', { slot_id: slot.id }, (json) => {
      setLogs(_.groupBy(json, (log) => dayjs(log.created_at).format('YYYY-MM-DD')))
    })
  }

  const title = t('Admin.AdminSlotPage.logHeader')

  const CurrentLogs = () => {
    if (Object.keys(logs).length === 0) {
      return <CommonLogsPanel logs={[]} date={slot.begin_date} title={title} slotId={slot.id} />
    }
    return Object.keys(logs).map((key, index) => {
      return <CommonLogsPanel logs={logs[key]} date={key} key={`log-${index}`} title={title} slotId={slot.id} />
    })
  }

  return (< CurrentLogs />)
}
