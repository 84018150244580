import { Button } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Color } from '../../Common/Color'
import { CalendarOutlined } from '@ant-design/icons'

export default function AvailableNowCard ({ setModalVisible, setRangeDisable, form, minimized = false }) {
  const { t } = useTranslation()
  const onClick = () => {
    setRangeDisable(true)
    setModalVisible(true)
    form.setFieldsValue({
      slot_type: 'patient',
      range: [dayjs(), dayjs().add(1, 'hour')],
      is_now: true
    })
  }

  return (
    <>
      {minimized && <Button type="primary" size="large" icon={<CalendarOutlined />} onClick={onClick} className='hoverable'
        style={{ '--hover-color': Color.red, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', border: '1px solid darkgrey' }} />
      }
      { !minimized &&
        <div style={{
          boxShadow: '0px 0px 2px 2px #f2f2f2',
          borderRadius: '1em',
          padding: '1em',
          margin: '1em',
          maxWidth: '290px',
          fontSize: '1.2em',
          textAlign: 'center'
        }}>
            <div style={{ color: Color.green, fontWeight: 'bold' }}>
                {t('Admin.Box.availableNow.title')}
            </div>

          <Button style={{ marginTop: '0.5em' }} onClick={onClick} type="primary" icon={<CalendarOutlined />}>
                {t('Admin.Box.availableNow.button')}
            </Button>
        </div>
      }
    </>
  )
}
