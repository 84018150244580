import React from 'react'
import { t } from 'i18next'
import { Button, Card, Tag } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const stateMessage = (slot) => {
  return (
    <div >
      <div>{slot.box.name} - {slot.box.interphone}</div>
      <div>{dayjs(slot.begin_date).format('H[h]mm')}-${dayjs(slot.end_date).format('H[h]mm')}</div>
      <div>{slot.digicode}</div>
    </div>
  )
}

export default function SlotCard ({ slot }) {
  const title = (<div style={{ padding: '1em 0' }}>
    <div style={{ color: t(`Admin.Slot.stateColor.${slot.state}`) }}>
      <span>{slot.name}</span>
      <span style={{ paddingLeft: '1em' }}><Tag color={t(`Admin.Slot.stateColor.${slot.state}`)}>{t(`Admin.Slot.state.${slot.state}`)}</Tag></span>
    </div>
    <div>+{slot.phone}</div>

  </div>)
  return (
    <Card title={title} bordered={true}
      actions={[
        <Link key='edit' to={`/admin/slots/${slot.id}?from=list`}><Button>Edition</Button></Link>
      ]}>
      {stateMessage(slot)}
    </Card>
  )
}
