import { Button, Modal, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { post } from '../../Common/api'
import EventForm from './EventForm'
import { Color } from '../../Common/Color'
import { WarningOutlined } from '@ant-design/icons'

export default function CreateEventCard ({ box, slot, minimized = false }) {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = useForm()
  const navigate = useNavigate()

  const onClick = () => {
    setModalVisible(true)
  }

  const onAdd = async (values) => {
    setLoading(true)
    await post('admin/events', values, (response) => {
      setModalVisible(false)
      navigate(`/admin/events/${response.event.id}`)
    })
  }

  return (
    <>
      <Modal
        open={modalVisible}
        width="800px"
        title={t('Admin.Event.title.create')}
        okText={t('actions.validate')}
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={() => setModalVisible(false)}
      >
        <Spin spinning={loading}>
          <EventForm form={form} onFinish={onAdd} slotId={slot?.id} boxId={box?.id} />
        </Spin>
      </Modal>
      { minimized && <Button type="primary" size="large" icon={<WarningOutlined />} onClick={onClick} className='hoverable'
        style={{ '--hover-color': Color.red, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', border: '1px solid darkgrey' }} />
      }
      { !minimized &&
        <div style={{
          boxShadow: '0px 0px 2px 2px #f2f2f2',
          borderRadius: '1em',
          padding: '1em',
          margin: '1em',
          maxWidth: '290px',
          fontSize: '1.2em',
          textAlign: 'center'
        }}>
          <div style={{ fontWeight: 'bold' }}>
            {t('Admin.Event.create')}
          </div>

          <Button style={{ marginTop: '0.5em' }} onClick={onClick} type="primary" icon={<WarningOutlined />}>
            {t('Admin.Event.createButton')}
          </Button>
        </div>
      }
    </>
  )
}
