import React from 'react'
import { t } from 'i18next'
import { Input } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export default function GepetoFilter ({ filter, onChangeFilter }) {
  const onChange = (hash) => {
    onChangeFilter({ ...filter, ...hash })
  }

  const screens = useBreakpoint()

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Input
        style={{ width: '150px' }}
        onChange={(e) => { onChange({ name: e.target.value }) }} placeholder={t('Admin.GepetoFilter.name')}
        value={filter.name}
      />
      {!screens.xs && <Input
        style={{ width: '150px' }}
        onChange={(e) => { onChange({ zip_code: e.target.value }) }} placeholder={t('Admin.GepetoFilter.zipCode')}
        value={filter.zip_code}
      />
      }
    </div>
  )
}
