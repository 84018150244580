import { Spin, notification } from 'antd'
import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import { deleteRequest, get, post, put, putMultipart } from '../../Common/api'
import i18next from 'i18next'

const BoxContext = createContext()
export default BoxContext

export const BoxContextProvider = () => {
  const [loading, setLoading] = useState(false)
  const [box, setBox] = useState(null)
  const [slots, setSlots] = useState([])
  const [days, setDays] = useState([])
  const [owners, setOwners] = useState([])
  const [slotsDate, setSlotsDates] = useState([])
  const { id } = useParams()

  const setCurrentBox = (json) => {
    json.gps_point = json.gps_point.replace(/POINT *\(/, '').replace(')', '')
    setBox(json)
  }

  const loadBox = async () => {
    setLoading(true)
    await get(`admin/boxes/${id}`, {}, (json) => {
      setLoading(false)
      setCurrentBox(json)
    }, () => {
      setLoading(false)
    })
  }

  const loadSlots = async (boxId, date) => {
    if (!boxId) return
    setSlotsDates([...slotsDate, date.toString()])
    setLoading(true)
    await get('admin/slots', { box_id: boxId, date, scope: 'calendar' }, (json) => {
      setLoading(false)
      setSlots([...slots, ...json])
    }, () => {
      setLoading(false)
    })
  }

  const loadDays = async (boxId, date) => {
    if (!boxId) return
    setLoading(true)
    await get('admin/days', { box_id: boxId, start_date: date }, (json) => {
      setLoading(false)
      setDays(json)
    }, () => {
      setLoading(false)
    })
  }

  const loadDefaultDays = async (boxId) => {
    if (!boxId) return
    setLoading(true)
    await get('admin/days/default_index', { box_id: boxId }, (json) => {
      setLoading(false)
      setDays(json)
    }, () => {
      setLoading(false)
    })
  }

  const savePeriod = async (params, form, onSuccess) => {
    setLoading(true)
    params = { ...params, start: params.range[0], end: params.range[1], box_id: box.id }
    params.id ? updatePeriod(params, form, onSuccess) : createPeriod(params, form, onSuccess)
  }

  const createPeriod = async (params, form, onSuccess) => {
    await post('admin/periods', params, (json) => {
      const day = days.find((day) => day.id === json.day_id)
      day.periods = [...day.periods, json]
      setLoading(false)
      onSuccess()
    }, (errors) => {
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
      setLoading(false)
    })
  }

  const updatePeriod = async (params, form, onSuccess) => {
    await put(`admin/periods/${params.id}`, params, (json) => {
      const day = days.find((day) => day.id === json.day_id)
      day.periods = [...day.periods, json]
      setLoading(false)
      onSuccess()
    }, (errors) => {
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
      setLoading(false)
    })
  }

  const deletePeriod = async (id, onSuccess) => {
    setLoading(true)
    await deleteRequest(`admin/periods/${id}`, {}, (json) => {
      const day = days.find((day) => day.id === json.day_id)
      day.periods = day.periods.filter(period => period.id !== id)
      setDays([...days, day])
      setLoading(false)
      onSuccess()
    })
  }

  const updateBox = async (params, form) => {
    setLoading(true)
    if (typeof params.picture === 'string') {
      delete params.picture
    }
    if (typeof params.waiting_time === 'string') {
      delete params.waiting_time
    }
    params.intervener_ids = form.getFieldValue('interveners').map(i => i.id)
    await putMultipart(`admin/boxes/${box.id}`, params, (json) => {
      setLoading(false)
      form.setFields(
        Object.keys(json).map((key) => ({
          name: key,
          errors: null
        }))
      )
      setCurrentBox(json)
      notification.success({
        message: i18next.t('Admin.message.updateSuccess'),
        placement: 'bottom'
      })
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  const loadOwners = async () => {
    setLoading(true)
    await get('admin/owners', { }, (res) => {
      setLoading(false)
      setOwners(res.owners)
    }, () => {
      setLoading(false)
    })
  }

  const saveSlot = async (params, form, afterSuccess) => {
    params = { ...params, begin_date: params.range[0], end_date: params.range[1], box_id: box.id }
    setLoading(true)
    await post('admin/slots', params, (json) => {
      setLoading(false)
      setSlots([...slots, json])
      afterSuccess()
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  useEffect(() => {
    loadBox()
    loadOwners()
  }, [])

  const values = {
    loading,
    box,
    owners,
    slots,
    loadSlots,
    saveSlot,
    updateBox,
    loadDefaultDays,
    days,
    savePeriod,
    deletePeriod,
    loadDays
  }
  return <BoxContext.Provider value={values}>
    <Spin spinning={loading}> <Outlet/> </Spin>
  </BoxContext.Provider>
}
