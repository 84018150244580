import { Layout, Spin, Menu } from 'antd'
import { t } from 'i18next'
import React, { useState } from 'react'
import AdminSlotForm from './AdminSlotForm'
import { Link } from 'react-router-dom'
import LogsPanel from './LogsPanel'
import CreateEventCard from '../EventsPage/CreateEventCard'
import { Color } from '../../Common/Color'
import SMSPanel from '../SMSPanel/SMSPanel'
import { LeftOutlined } from '@ant-design/icons'

const { Header, Content } = Layout

export default function AdminSlotCard ({ slot, loading, setLoading, cancel, back }) {
  const [displayPanel, setDisplayPanel] = useState('info')
  const title = <div style={{ flexDirection: 'row', display: 'flex' }}>
    <LeftOutlined onClick={back} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
    <span>{slot.first_name} {slot.last_name}</span>
  </div>

  const items = () => [
    {
      key: 'info', label: (<Link onClick={() => (setDisplayPanel('info'))}>{t('Admin.Slot.menu.info')}</Link>)
    },
    {
      key: 'log', label: (<Link onClick={() => (setDisplayPanel('log'))}>{t('Admin.Slot.menu.log')}</Link>)
    },
    {
      key: 'sms', label: (<Link onClick={() => (setDisplayPanel('sms'))}>{t('Admin.Slot.menu.sms')}</Link>)
    }
  ]
  return (
    <>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>{title}</Layout>
      <Header style={{ padding: 0, backgroundColor: Color.white, color: Color.dark_blue }}>
        <Menu style={{ float: 'left', backgroundColor: Color.white, color: Color.dark_blue, width: '100%' }}
          onClick={(e) => setDisplayPanel(e.key)}
          selectedKeys={[displayPanel]}
          mode='horizontal'
          items={items()} />
      </Header>
      <Content style={{ padding: '1em', backgroundColor: 'white' }}>
        <Spin spinning={loading}>
          {displayPanel === 'info' && <>
            <AdminSlotForm slot={slot} setLoading={setLoading} cancel={cancel} back={back} />
            <CreateEventCard box={{ id: slot.box_id }} slot={slot} setLoading={setLoading} cancel={cancel} back={back} />
          </>}
          {displayPanel === 'log' && <LogsPanel slot={slot} />}
          {displayPanel === 'sms' && <SMSPanel owner={slot} ownerType="slots" />}
        </Spin>
      </Content>
    </>
  )
}
