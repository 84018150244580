import React from 'react'
import { Col, Row } from 'antd'
import PublicFooter from '../PublicFooter'
import PublicHeader from '../PublicHeader'
import { Color } from '../../Common/Color'
import { t } from 'i18next'

export default function ErrorPage ({ error, name }) {
  return (<div style={{ gap: '1em', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: Color.white }}>
    <PublicHeader withSearch={true} />
    <Row style={{ background: 'linear-gradient(white, teal, white)', height: '90vh' }} className='hide-md'>
      <Col span={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

        <div style={{ backgroundColor: '#fff', borderRadius: '0 30px 30px 0' }}>
          <div className="contentPadding" style={cssTitle}>
            {t(`Public.ErrorPage.${error}.title`)}
          </div>
          <div className="contentPadding" style={{ fontSize: '18px', maxWidth: '1200px', margin: '2.5em 0' }}>
            {t(`Public.ErrorPage.${error}.description`, { name })}
          </div>
        </div>
      </Col>
      <Col span={16}>
        <img style={{}} src="/images/box-exterieur.png" title="Image de la Box Médicale extérieur" height={400} />
      </Col>
    </Row>
    <Row style={{ background: 'linear-gradient(white, teal, white)', height: '90vh' }} className='show-md'>
      <Col span={23} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

        <div style={{ backgroundColor: '#fff', borderRadius: '0 30px 30px 0' }}>
          <div className="contentPadding" style={cssTitle}>
            {t(`Public.ErrorPage.${error}.title`)}
          </div>
          <div className="contentPadding" style={{ fontSize: '18px', maxWidth: '1200px', margin: '2.5em 0' }}>
            {t(`Public.ErrorPage.${error}.description`, { name })}
          </div>
        </div>
      </Col>
    </Row>
    <PublicFooter />
  </div>)
}
const cssTitle = {
  fontSize: '30px',
  fontWeight: 'bold',
  borderWidth: '2px 2px 2px 0px',
  borderStyle: 'solid',
  borderRadius: '0px 30px 30px 0px',
  display: 'inline-block',
  margin: '0.5em 0'
}
