import { Button, Col, Layout, Row, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { Color } from '../../Common/Color'
import { get } from '../../Common/api'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { LeftOutlined } from '@ant-design/icons'
import { Commands, Digicode, DisplayData, DisplayVersion, GepetoInfo, PingLog } from './GepetoCommon'
import consumer from '../../Common/cable'

const { _Header, Content } = Layout

export default function GepetoPage () {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [gepeto, setGepeto] = useState(null)

  const loadGepeto = async () => {
    setLoading(true)
    await get(`admin/gepetos/${id}`, {}, (json) => {
      setLoading(false)
      setGepeto(json)
    }, () => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadGepeto()
  }, [])

  useEffect(() => {
    consumer.subscriptions.create({ channel: 'GepetoChannel' }, {
      connected: () => { console.log('connected GepetoChannel') },
      disconnected: () => { },
      received: (data) => { if (data.id === id) { setGepeto(data) } }
    })
  }, [])

  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 4
  const largeSpan = screens.xs ? 24 : 6

  return (
    <>
      <Spin spinning={loading}> <Outlet /> </Spin>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <Row >
          <Col>
            <Link to={'/admin/gepetos'}><Button type="text" icon={<LeftOutlined />} /></Link>
          </Col>
          <Col style={{ fontSize: '24px' }}>{gepeto?.box?.name}</Col>
        </Row>
      </Layout>
      <Content>
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          <h2>{t('Admin.GepetoPage.title')}</h2>
          {gepeto &&
            <div style={{ display: 'flex' }}>
              <Col>
                <Row>
                  <Col span={span}>
                    <h3>{t('Admin.GepetoPage.network')}</h3>
                    <GepetoInfo gepeto={gepeto} />
                  </Col>
                  <Col span={span}>
                    <h3>{t('Admin.GepetoPage.data')}</h3>
                    <DisplayData gepeto={gepeto} />
                  </Col>
                  <Col span={span}>
                    <h3>{t('Admin.GepetoPage.version')}</h3>
                    <DisplayVersion gepeto={gepeto} />
                  </Col>
                  <Col span={largeSpan}>
                    <h3>{t('Admin.GepetoPage.commands')}</h3>
                    <Commands gepeto={gepeto} setGepeto={setGepeto} />
                  </Col>
                  <Col span={largeSpan} >
                    <PingLog latencies={gepeto.latencies} title={<h3>{t('Admin.GepetoPage.ping_log')}</h3>} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>{t('Admin.GepetoPage.digicode')}</h3>
                    <Digicode gepeto={gepeto} setGepeto={setGepeto} />
                  </Col>
                </Row>
              </Col>
            </div>
          }
        </Layout>
      </Content>
    </>
  )
}
