import { DatePicker, Layout } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { get } from '../../Common/api'
import LogsPanel from '../Logs/LogsPanel'
import BoxContext from './BoxContext'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Color } from '../../Common/Color'

export default function BoxLogsPage () {
  const [logs, setLogs] = useState()
  const [date, setDate] = useState(dayjs())
  const { box } = useContext(BoxContext)

  useEffect(() => {
    if (box) {
      getLogs(date)
    }
  }, [box, date])

  const getLogs = async (date) => {
    await get('admin/logs/box', { box_id: box.id, date: date.format('YYYY-MM-DD') }, (json) => {
      setLogs(json)
    })
  }

  const onChange = (value) => {
    setDate(value)
  }

  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LeftOutlined onClick={() => setDate(date.add(-1, 'day'))} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
        <DatePicker style={{ margin: '1em 0em', width: '10em' }} value={date} format={'DD/MM/YYYY'} onChange={onChange}/>
        <RightOutlined onClick={() => setDate(date.add(1, 'day'))} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
      </div>
      <LogsPanel logs={logs} date={date} boxId={box?.id} />
    </Layout>
  )
}
