import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { CircleMarker, MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import { Color } from '../../Common/Color'

export default function BoxCreationForm ({ form, onFinish, owners }) {
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState()
  const [mapVisible, setMapVisible] = useState(false)

  const onSubmit = (values) => {
    if (values.gps_point) {
      values.gps_point = `POINT(${values.gps_point})`
    } else {
      values.gps_point = null
    }
    onFinish(values)
  }

  useEffect(() => {
    setTimeout(() => {
      setMapVisible(true)
    }, 500)
  }, [])

  useEffect(() => {
    map?.invalidateSize()
    const gpsPoint = form.getFieldValue('gps_point')
    if (gpsPoint) {
      const newCenter = gpsPoint ? gpsPoint.split(' ').reverse() : null
      setCenter(newCenter)
      map?.setView(newCenter, map.getZoom())
    }
  }, [mapVisible, map])

  const onGpsChange = (e) => {
    if (/^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g.test(e.target.value)) {
      const newCenter = e.target.value.split(' ').reverse()
      setCenter(newCenter)
    }
  }

  const LocationFinderDummy = () => {
    useMapEvents({
      click (e) {
        form.setFieldsValue({ gps_point: `${e.latlng.lng} ${e.latlng.lat}` })
        setCenter([e.latlng.lat, e.latlng.lng])
      }
    })
    return null
  }

  return (
    <Form
      id="boxForm"
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onSubmit}
      initialValues={{ interveners: [] }}
    >
      <Row gutter={[16, 16]}>
        <Col span={9}>
          <Form.Item name="name" label={t('Admin.BoxForm.name')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="city" label={t('Admin.BoxForm.city')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="zip_code" label={t('Admin.BoxForm.zip_code')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="address" label={t('Admin.BoxForm.address')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="extra_address" label={t('Admin.BoxForm.extra_address')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="gps_point" label={t('Admin.BoxForm.gps')}
            rules={[{ required: true, pattern: /^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g }]}
          >
            <Input onChange={onGpsChange} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="owner_id" label={t('Admin.BoxForm.owner')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Select
              showSearch
              placeholder="Selectionner une collectivité"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={owners?.map((o) => ({ label: o.name, value: o.id }))}
            />
          </Form.Item>
        </Col>
      </Row>
      {mapVisible && <MapContainer
        ref={setMap}
        style={{ borderRadius: '2em', height: '200px', width: '100%' }} center={center || [48.856614, 2.3522219]} zoom={13} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {center && <CircleMarker center={center} radius={8} pathOptions={{ color: Color.white, fillColor: Color.red, weight: 2, fillOpacity: 1 }}></CircleMarker>}
        <LocationFinderDummy />
      </MapContainer>}
    </Form>
  )
}
