import { List, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import consumer from '../../Common/cable'
import _ from 'lodash'

export default function LogsPanel ({ logs, date, title, slotId, boxId }) {
  const [newLog, setNewLog] = useState(null)
  const [displayLogs, setDisplayLogs] = useState([])
  const Title = <div style={{ textAlign: 'center' }}>
    {title && <h3 style={{ textAlign: 'center' }}>{title}</h3>}
    {dayjs(date).format('dddd DD MMMM YYYY')}
  </div>

  useEffect(() => {
    if ((dayjs(newLog?.created_at).isSame(dayjs(date), 'day')) &&
      ((newLog?.slot_id === slotId) || (newLog?.box_id === boxId))) {
      setDisplayLogs([newLog, ...displayLogs])
    }
  }, [newLog])

  useEffect(() => {
    setDisplayLogs(logs)
  }, [logs])

  useEffect(() => {
    consumer.subscriptions.create({ channel: 'LogsChannel' }, {
      connected: () => { console.log('connected LogsChannel') },
      disconnected: () => { },
      received: (data) => { setNewLog(data) }
    })
  }, [])

  return (
    <List
      header={Title}
      bordered
      rowKey={(record) => record?.id}
      style={{ maxHeight: '45em', overflowY: 'scroll' }}
      dataSource={_.orderBy(displayLogs, ['created_at'], ['desc'])}
      renderItem={(log) => (
        <List.Item>
          <Tag color={log.color}>
            <span style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              {dayjs(log.created_at).format('HH:mm:ss')}
            </span>
          </Tag>
          {log.message}
        </List.Item>
      )}
    />
  )
}
