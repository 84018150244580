import { Button, DatePicker, Form, Input, Modal, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import BoxContext from '../BoxPage/BoxContext'
import { PlusOutlined } from '@ant-design/icons'
import { Color } from '../../Common/Color'
const { RangePicker } = DatePicker

export default function CreatePeriodCard ({ days, loadCalendarData, minimized = false }) {
  const { savePeriod, loading } = useContext(BoxContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = useForm()

  const onClick = () => {
    form.resetFields()
    setModalVisible(true)
  }

  const onAdd = async (values) => {
    values.day_id = days.find((day) => dayjs(day.date).format('DD/MM/YYYY') === values.range[0].format('DD/MM/YYYY')).id
    await savePeriod(values, form, () => {
      setModalVisible(false)
      loadCalendarData({ start: dayjs().subtract(2, 'day').toString() })
    })
  }

  const onDateChange = (value) => {
    if (value[0].isSame(value[1])) {
      form.setFieldsValue({ range: [value[0], value[1].add(1, 'hour')] })
    }
  }

  return (
    <>
      <Modal
        open={modalVisible}
        width="800px"
        title={t('Admin.Period.title.create')}
        okText={t('actions.validate')}
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={() => setModalVisible(false)}
      >
        <Spin spinning={loading}>
          <Form
          form={form} onFinish={onAdd}
          initialValues={{ period_type: 'open' }}
          >
            <Form.Item name="period_type" hidden >
              <Input />
            </Form.Item>
            <Form.Item name="range" label={t('Admin.PeriodForm.range')} >
              <RangePicker
                onChange={onDateChange}
                showTime={{ format: 'HH[h]mm' }}
                format={'DD/MM/YY HH[h]mm'} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      { minimized && <Button type="primary" size="large" icon={<PlusOutlined />} onClick={onClick} className='hoverable'
        style={{ '--hover-color': Color.red, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', border: '1px solid darkgrey' }} />
      }
      { !minimized &&
        <div style={{
          boxShadow: '0px 0px 2px 2px #f2f2f2',
          borderRadius: '1em',
          padding: '1em',
          margin: '1em',
          maxWidth: '290px',
          fontSize: '1.2em',
          textAlign: 'center'
        }}>
          <div style={{ fontWeight: 'bold' }}>
            {t('Admin.Period.create')}
          </div>

          <Button style={{ marginTop: '0.5em' }} onClick={onClick} type="primary" icon={<PlusOutlined />}>
            {t('Admin.Period.createButton')}
          </Button>
        </div>
      }
    </>
  )
}
