import React from 'react'
import { Col, Row } from 'antd'
import IntervenerCard from './IntervenerCard'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const Cards = ({ interveners, updateIntervener }) => {
  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 6
  const result = []
  interveners.forEach((intervener) => {
    result.push(
    <Col span={span} key={intervener.id}>
      <IntervenerCard intervener={intervener} updateIntervener={updateIntervener} />
      </Col>
    )
  })
  return result
}

export default function IntervenerCards ({ interveners, updateIntervener }) {
  const pad = { xs: 8, sm: 16, md: 24, lg: 32 }

  return (
    <Row gutter={[pad, pad]} justify="center">
      <Cards interveners={interveners} updateIntervener={updateIntervener} />
    </Row>
  )
}
