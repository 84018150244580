import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Color } from '../../Common/Color'
import { DisplayContact } from '../IntervenerPage/IntervenerCommon'
import { Link } from 'react-router-dom'

const columns = (openModal, deleteModal) => [
  {
    width: 100,
    title: t('Admin.IntervenersList.table.boxes'),
    dataIndex: 'boxes',
    key: 'boxes',
    render: (_, o) => o?.boxes?.map((box) => box.name).join(' - ') || 'Aucune box'
  },
  {
    width: 50,
    title: t('Admin.IntervenersList.table.job.label'),
    dataIndex: 'job',
    key: 'job',
    render: (_, o) => t(`Admin.IntervenersList.table.job.${o.job}`),
    sorter: (a, b) => t(`Admin.IntervenersList.table.job.${a.job}`).localeCompare(t(`Admin.IntervenersList.table.job.${b.job}`))
  },
  {
    width: 75,
    title: t('Admin.IntervenersList.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    width: 150,
    title: t('Admin.IntervenersList.table.phones'),
    dataIndex: 'phones',
    key: 'phones',
    render: (_, o) => < DisplayContact fields={(o?.phones.map(e => ({ ...e, key: e.id })))} />
  },
  {
    width: 150,
    title: t('Admin.IntervenersList.table.emails'),
    dataIndex: 'emails',
    key: 'emails',
    render: (_, o) => <DisplayContact fields={(o?.emails.map(e => ({ ...e, key: e.id })))} />
  },
  {
    width: 30,
    key: 'action',
    render: (_, o) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Link to={`/admin/interveners/${o.id}`}><Button shape="circle" icon={<FormOutlined />} /></Link>
      <Button shape="circle" icon={<DeleteOutlined />} onClick={() => deleteModal(o)} style={{ color: Color.primary }} />
    </div>
  }
]

export default function IntervenersList ({ interveners, openModal, deleteModal }) {
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns(openModal, deleteModal)}
      dataSource={interveners.map((intervener) => ({ ...intervener, key: intervener.id }))}
    />
  )
}
