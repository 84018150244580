import { Button, Col, Form, Input, notification, Radio, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { t } from 'i18next'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import PhoneInput from 'react-phone-number-input/input'
import { JOBS } from '../../Common/Common'
import { useForm } from 'antd/es/form/Form'
import { put } from '../../Common/api'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useNavigate } from 'react-router'

export default function IntervenerForm ({ intervener, setLoading, action, addRequest, closeModal }) {
  const [form] = useForm()
  const screens = useBreakpoint()
  const navigate = useNavigate()

  useEffect(() => {
    form.resetFields()
    if (intervener) {
      form.setFieldsValue(intervener)
    }
  }, [intervener])

  const request = async (values) => {
    await put(`admin/interveners/${values.id}`, values, () => {
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  const onFinish = (values) => {
    const phones = values.phones?.map((phone) => ({ ...phone, value: phone.value.slice(1) })) || []
    const toDelete = values.fields?.filter(field => !phones.concat(values.emails).map(e => e.id).includes(field.id)) || []
    values.fields_attributes = phones.concat(values.emails).concat(toDelete.map(field => ({ ...field, _destroy: '1' })))
    if (action === 'add') { addRequest(values) } else { request(values) }
  }

  const span = screens.xs ? 24 : 6

  const back = () => {
    navigate('/admin/interveners')
  }

  return (
    <div style={{ position: 'relative' }}>
      <Form
        form={form}
        labelAlign='left'
        onFinish={onFinish}
      >
        <Form.Item name='id' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='fields' hidden>
          <Input />
        </Form.Item>
        <Form.Item name="job" label={t('Admin.IntervenerForm.job.label')}
          rules={[{ required: true, message: t('rules.required') }]}
        >
          <Radio.Group>
            {JOBS.map((job) => (
              <Radio key={job} value={job}>{t(`Admin.IntervenerForm.job.${job}`)}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Row gutter={16}>
          <Col>
            <Form.Item name="last_name" label={t('Admin.IntervenerForm.lastName')}
              rules={[{ required: true, message: t('rules.required') }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="first_name" label={t('Admin.IntervenerForm.firstName')}
              rules={[{ required: true, message: t('rules.required') }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="company" label={t('Admin.IntervenerForm.company')}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <h3>{t('Admin.IntervenerForm.phones')}</h3>
        <Form.List name="phones">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: t('rules.required') }]}
                    label={t('Admin.IntervenerForm.phone')}
                  >
                    <PhoneInput className='ant-input css-dev-only-do-not-override-xfszwz' international defaultCountry={'FR'}
                      placeholder="06 39 98 80 12" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    label={t('Admin.IntervenerForm.description')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'field_type']} hidden initialValue={'phone'}>
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t('actions.add')} ${t('Admin.IntervenerForm.aPhone')}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <h3>{t('Admin.IntervenerForm.emails')}</h3>
        <Form.List name="emails">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: t('rules.required') }]}
                    label={t('Admin.IntervenerForm.email')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    label={t('Admin.IntervenerForm.description')}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={[name, 'field_type']} hidden initialValue={'email'}>
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t('actions.add')} ${t('Admin.IntervenerForm.anEmail')}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>

      <Row>
        <Col span={span} style={{ paddingBottom: '10px' }} >
          <Button type="primary" htmlType="submit" onClick={form.submit} >
            {t('Admin.btn.submit')}
          </Button>
        </Col>
        {(action === 'update') &&
          <Col span={span} style={{ position: 'absolute', right: '5px' }} >
            <Button type="primary" onClick={back} >
              {t('Admin.btn.back')}
            </Button>
          </Col>
        }
        {(action === 'add') &&
          <Col span={span} style={{ position: 'absolute', right: '5px' }} >
            <Button type="primary" onClick={closeModal} >
              {t('actions.cancel')}
            </Button>
          </Col>
        }
      </Row>
    </div>
  )
}
