import React from 'react'
import { Col, Popover, Row } from 'antd'
import { formatPhoneNumber } from 'react-phone-number-input'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export const DisplayContact = ({ fields }) => {
  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 6

  const value = (field) => (
    field.field_type === 'phone' ? formatPhoneNumber(field.value) : field.value
  )
  return (
    <Row >
      {fields?.map((field) => (
        <Col span={span} key={`contact-${field.description}`}>
          {field.description &&
            <Popover content={field.description}>
              {screens.xs &&
                <span>{field.description} : </span>
              }
              {value(field)}
            </Popover>
          }
          {!field.description && value(field)}
        </Col>
      ))}
    </Row>
  )
}
