import { DatePicker, Layout, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { get } from '../../Common/api'
import { Color } from '../../Common/Color'
import SlotList from './SlotList'
import SlotFilter from './SlotFilter'
import SlotCards from './SlotCards'
import dayjs from 'dayjs'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export default function SlotsPage () {
  const [slots, setSlots] = useState([])
  const params = new URLSearchParams(location.search)
  const day = params.get('day')
  const [date, setDate] = useState(day ? dayjs(day) : dayjs())
  const [filter, setFilter] = useState({ name: '', box: '', phone: '' })
  const [loading, setLoading] = useState(false)

  const screens = useBreakpoint()

  useEffect(() => {
    getSlots()
  }, [date])

  const getSlots = async () => {
    setLoading(true)
    await get('admin/slots', { scope: 'filtered_list', date: date.format() }, (res) => {
      setLoading(false)
      setSlots(res.slots)
    }, () => {
      setLoading(false)
    })
  }

  const update = (value) => {
    setDate(value)
  }

  const onChangeFilter = (filter) => {
    setFilter(filter)
  }

  const filteredSlots = slots.filter((slot) => {
    return slot.box.name.toLowerCase().includes(filter.box.toLowerCase()) &&
      slot.name.includes(filter.name) &&
      slot.phone.includes(filter.phone)
  })

  return (
    <Layout>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{slots.length}</b> {t('Admin.SlotsPage.title')}</div>
          <SlotFilter filter={filter} onChangeFilter={onChangeFilter} />
        </div>
      </Layout>
      <Layout style={{ flexDirection: 'row', display: 'flex', margin: 'auto', width: '150px', marginBottom: '1em' }}>
        <LeftOutlined onClick={() => setDate(date.add(-1, 'day'))} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
        <DatePicker value={date} onChange={update} format={'DD/MM/YYYY'} />
        <RightOutlined onClick={() => setDate(date.add(1, 'day'))} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
      </Layout>
      <Spin spinning={loading}>
      {!screens.xs && <SlotList slots={filteredSlots} />}
      {screens.xs && <SlotCards slots={filteredSlots} />}
      </Spin>
    </Layout>

  )
}
