import { Layout, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import GepetoList from './GepetoList'
import { get } from '../../Common/api'
import consumer from '../../Common/cable'
import GepetoCards from './GepetoCards'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { Color } from '../../Common/Color'
import GepetoFilter from './GepetoFilter'

export default function GepetosPage () {
  const [gepetos, setGepetos] = useState([])
  const [gepeto, setGepeto] = useState({})
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ name: '', zip_code: '' })
  const screens = useBreakpoint()

  useEffect(() => {
    getGepetos()
    componentDidMount()
  }, [])

  const onChangeFilter = (filter) => {
    setFilter(filter)
  }

  const filteredGepetos = gepetos.filter((gepeto) => {
    return gepeto.box.name.toLowerCase().includes(filter.name.toLowerCase()) &&
      gepeto.box.zip_code.includes(filter.zip_code)
  })

  const getGepetos = async () => {
    setLoading(true)
    await get('admin/gepetos', {}, (res) => {
      setLoading(false)
      setGepetos(res.gepetos)
    }, () => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (gepeto?.id) {
      updateGepeto(gepeto)
    }
  }, [gepeto]
  )

  const updateGepeto = (gepeto) => {
    const index = gepetos.findIndex(
      e => e.id === gepeto.id
    )
    setGepetos([
      ...gepetos.slice(0, index),
      gepeto,
      ...gepetos.slice(index + 1, gepetos.length)
    ])
  }

  const componentDidMount = () => {
    consumer.subscriptions.create({ channel: 'GepetoChannel' }, {
      connected: () => { console.log('connected GepetoChannel') },
      disconnected: () => { },
      received: (data) => { setGepeto(data) }
    })
  }

  return (
    <Layout>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{gepetos.length}</b> {t('Admin.GepetosPage.title')}</div>
          <GepetoFilter filter={filter} onChangeFilter={onChangeFilter} />
        </div>
      </Layout>
      <Spin spinning={loading}>
        {!screens.xs && <GepetoList gepetos={filteredGepetos} />}
        {screens.xs && <GepetoCards gepetos={filteredGepetos} updateGepeto={updateGepeto} />}
      </Spin>
    </Layout>
  )
}
