import React, { useEffect, useState } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import AdminSlotCard from './AdminSlotCard'
import AdminSlotPage from './AdminSlotPage'
import { useNavigate, useParams } from 'react-router'
import { get, post } from '../../Common/api'
import dayjs from 'dayjs'
import { notification } from 'antd'
import { t } from 'i18next'

export default function AdminSlot () {
  const { id } = useParams()
  const params = new URLSearchParams(location.search)
  const from = params.get('from')
  const [slot, setSlot] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getSlot()
    setLoading(false)
  }, [])

  const screens = useBreakpoint()

  const getSlot = async () => {
    await get(`admin/slots/${id}`, {}, (json) => { setSlot(json) })
  }

  const cancel = () => {
    setLoading(true)
    post(`admin/slots/${id}/cancel`, {}, (json) => {
      setSlot(json)
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, () => {
      setLoading(false)
      notification.error({ message: t('Admin.message.updateError'), placement: 'bottom' })
    })
  }

  const back = () => {
    if (from === 'list') {
      navigate(`/admin/slots?day=${dayjs(slot.begin_date).format('YYYY-MM-DD')}`)
    } else if (from === 'calendar') {
      navigate(`/admin/boxes/${slot.box_id}/calendar?day=${dayjs(slot.begin_date).format('YYYY-MM-DD')}`)
    } else {
      navigate('/admin/events')
    }
  }

  return (
    <>
      {screens.xs && <AdminSlotCard slot={slot} loading={loading} setLoading={setLoading} cancel={cancel} back={back} />}
      {!screens.xs && <AdminSlotPage slot={slot} loading={loading} setLoading={setLoading} cancel={cancel} back={back} />}
    </>
  )
}
