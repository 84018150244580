import React, { useState } from 'react'
import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'
import { Color } from '../../Common/Color'
import { Commands, Digicode, DisplayData, GepetoInfo } from '../GepetoPage/GepetoCommon'

export default function GepetoCard ({ gepeto, updateGepeto }) {
  const [displayPanel, setDisplayPanel] = useState('info')
  const title = (<div>
    <span style={{ color: gepeto.box.is_online ? Color.green : Color.dark_orange, padding: '1em' }}>{gepeto.box.name}</span>
  </div>)

  const type = (panel) => (displayPanel === panel ? 'primary' : 'default')
  return (
    <Card title={title} bordered={true}
      actions={[
        <Link key='info' onClick={() => (setDisplayPanel('info'))}><Button type={type('info')} >Info</Button></Link>,
        <Link key='data' onClick={() => (setDisplayPanel('data'))}><Button type={type('data')} >Data</Button></Link>,
        <Link key='commands' onClick={() => (setDisplayPanel('commands'))}><Button type={type('commands')} >Cmd</Button></Link>,
        <Link key='digicode' onClick={() => (setDisplayPanel('digicode'))}><Button type={type('digicode')} >Code</Button></Link>
      ]}>
      {displayPanel === 'commands' && <Commands gepeto={gepeto} setGepeto={updateGepeto} />}
      {displayPanel === 'data' && <DisplayData gepeto={gepeto} />}
      {displayPanel === 'info' && <GepetoInfo gepeto={gepeto} />}
      {displayPanel === 'digicode' && <Digicode gepeto={gepeto} setGepeto={updateGepeto} />}
    </Card>
  )
}
