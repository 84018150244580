import { Layout, Spin, Menu } from 'antd'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Color } from '../../Common/Color'
import IntervenerForm from './IntervenerForm'
import SMSPanel from '../SMSPanel/SMSPanel'
import { LeftOutlined } from '@ant-design/icons'
const { Header, Content } = Layout

export default function IntervenerCard ({ intervener, loading, setLoading }) {
  const [displayPanel, setDisplayPanel] = useState('info')
  const navigate = useNavigate()

  const title = <div style={{ flexDirection: 'row', display: 'flex' }}>
    <LeftOutlined onClick={() => navigate('/admin/interveners')} className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
    <span>{intervener.first_name} {intervener.last_name}</span>
  </div>

  const items = () => [
    {
      key: 'info', label: (<Link onClick={() => (setDisplayPanel('info'))}>{t('Admin.Intervener.menu.info')}</Link>)
    },
    {
      key: 'sms', label: (<Link onClick={() => (setDisplayPanel('sms'))}>{t('Admin.Intervener.menu.sms')}</Link>)
    }
  ]
  return (
    <>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>{title}</Layout>
      <Header style={{ padding: 0, backgroundColor: Color.white, color: Color.dark_blue }}>
        <Menu style={{ float: 'left', backgroundColor: Color.white, color: Color.dark_blue, width: '100%' }}
          onClick={(e) => setDisplayPanel(e.key)}
          selectedKeys={[displayPanel]}
          mode='horizontal'
          items={items()} />
      </Header>
      <Content style={{ padding: '1em', backgroundColor: 'white' }}>
        <Spin spinning={loading}>
          <span>{intervener.boxes?.map((box) => box.name).join(' - ') || 'Aucune box'}</span>
          {displayPanel === 'info' && <IntervenerForm intervener={intervener} setLoading={setLoading} action="update" />}
          {displayPanel === 'sms' && <SMSPanel owner={intervener} ownerType="interveners" setLoading={setLoading} />}
        </Spin>
      </Content>
    </>
  )
}
