import { Col, Layout, Row, Spin } from 'antd'
import React from 'react'
import IntervenerForm from './IntervenerForm'
import SMSPanel from '../SMSPanel/SMSPanel'

export default function IntervenerPage ({ intervener, loading, setLoading }) {
  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white', marginTop: '2em', justifyContent: 'space-between' }}>
      <Spin spinning={loading}>
        <Row>
          <Col span={10} >
            <IntervenerForm intervener={intervener} setLoading={setLoading} action="update"/>
          </Col>
          <Col span={5} style={{ margin: '0 1em 0 1em' }}>
            <SMSPanel owner={intervener} ownerType="interveners" setLoading={setLoading} />
          </Col>
        </Row>
      </Spin>
    </Layout >
  )
}
