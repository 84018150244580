import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Color } from './Color'

export default function Info ({ text, infoText }) {
  return (
    <>
      <span style={{ paddingRight: '1em' }}>{text}</span>
      <Tooltip placement="top" title={infoText} >
        <InfoCircleOutlined className='hoverable' style={{ cursor: 'pointer', '--hover-color': Color.red }} />
      </Tooltip>
    </>
  )
}
