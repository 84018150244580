import { Button, Checkbox, ConfigProvider, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router'
import { post } from '../../Common/api'

export default function SlotForm ({ box, date, isNow }) {
  const { t } = useTranslation()
  const [form] = useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const onFinishFailed = () => {
    setLoading(false)
  }

  const onFinish = (values) => {
    setLoading(true)
    const phone = values.phone
    if ((phone.slice(0, 3) === '+06') || (phone.slice(0, 3) === '+07')) {
      values.phone = '+33' + phone.slice(2)
    }
    values = {
      ...values,
      phone: values.phone.slice(1),
      date,
      box_id: box.id,
      is_now: isNow
    }
    post('public/slots', values, (json) => {
      if (json.sms_confirmed) {
        navigate(`/slots/${json.id}`)
      } else {
        navigate(`/box/${box.id}/confirm_slot/${json.id}`)
      }
    })
    setLoading(false)
  }

  return (
    <div className={'slotForm'} style={{ flex: 1 }}>
      <Form
        style={{ flex: 1, fontSize: '1.2em' }}
        labelCol={{ xs: { span: 4 }, sm: { span: 4 }, md: { span: 5 }, lg: { span: 4 } }}
        wrapperCol={{ xs: { span: 20 }, sm: { span: 8 }, md: { span: 10 }, lg: { span: 6 } }}
        id="SlotForm"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelWrap
      >
        <Form.Item name="first_name" label={t('Public.Slot.firstName')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label={t('Public.Slot.lastName')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label={t('Public.Slot.phone')}
          extra="+33 6 39 98 80 12"
          rules={[
            {
              validator: (_, value) =>
                value ? (isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))) : Promise.reject(new Error(t('rules.required')))
            }]}>
          <PhoneInput className='phone-large-input ant-input css-dev-only-do-not-override-xfszwz' international defaultCountry={'FR'} placeholder="+33 6 39 98 80 12" />
        </Form.Item>
        <Form.Item name="email" label={t('Public.Slot.email')}>
          <Input />
        </Form.Item>
        <ConfigProvider theme={{
          token: {
            controlInteractiveSize: 20,
            lineWidth: 2
          }
        }}>
          <Form.Item className="slot-checkbox" name="cgu" valuePropName="checked" labelCol={{ span: 10 }} colon={false}
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error(t('rules.required'))) }]}
            label={<p>{t('Public.Slot.cgu_1')} <a target="_blank" href="/cgu" >{t('Public.Slot.cgu_2')}</a></p>}>
            <Checkbox />
          </Form.Item>
          <Form.Item className="slot-checkbox" name="age_restriction" valuePropName="checked" labelCol={{ span: 10 }} colon={false}
            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error(t('rules.required'))) }]}
            label={t('Public.Slot.age_restriction')}>
            <Checkbox />
          </Form.Item>
        </ConfigProvider>
        <Form.Item wrapperCol={{ span: 11 }} style={{ textAlign: 'right', marginTop: '3em' }}>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            {t('Public.Slot.submit')}
          </Button>
        </Form.Item>
      </Form >
    </div>
  )
}
