import React, { useEffect, useState } from 'react'
import { Button, Col, Form, List, Popover, Row, Select, Spin } from 'antd'
import { get, post } from '../../Common/api'
import { Color } from '../../Common/Color'
import dayjs from 'dayjs'
import TextArea from 'antd/es/input/TextArea'
import consumer from '../../Common/cable'
import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { t } from 'i18next'

const style = (justifyContent) => ({ color: Color.dark_grey, fontSize: '0.8em', justifyContent })

const Content = ({ message, align, multiPhone }) => (
  <div className="sms-content">
    <div style={{ whiteSpace: 'pre-line' }}>{message.content}</div>
    <div style={{ lineHeight: '0.2', marginTop: '1em' }}>
      <div style={{ ...style(align), display: 'inline' }}>
        {dayjs(message.created_at).format('HH:mm:ss dddd DD')}
        {(message.status === 'DELIVERED') && <CheckOutlined style={{ color: Color.black, padding: '0.8em 1em' }} />}
        {(message.status === 'ENROUTE') && <Spin size="small" style={{ padding: '0 1em' }} />}
        {(message.status !== 'DELIVERED') && (message.status !== 'ENROUTE') &&
          <Popover content={t('Admin.SmsPanel.error.content', { status: message.status })} title={t('Admin.SmsPanel.error.title')}>
            <WarningOutlined style={{ color: Color.red, padding: '0.8em 1em' }} />
          </Popover>}
      </div>
      {multiPhone && message.phone &&
        <div style={{ ...style(align) }}>{t('Admin.SmsPanel.send_to')}{message.phone}</div>
      }
      <div style={{ ...style(align), display: 'grid' }}>{message.sender}</div>
    </div>
  </div>
)

const DistantMessage = ({ message }) => (
  <List.Item style={{ justifyContent: 'start', display: 'flex' }}>
    <Row >
      <Col style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }} span={2} >
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
          <polygon points="40 0, 100 60, 100 0" fill={Color.light_blue} />
        </svg>
        <span style={{ fontSize: '0.7em', paddingRight: '0.3em' }}>{message.initial}</span>
      </Col>
      <Col style={{ display: 'block', justifyContent: 'start', backgroundColor: Color.light_blue, padding: '1em', borderRadius: '0 1em 1em 1em' }} span={22} >
        <Content message={message} align='start' />
      </Col>
    </Row>
  </List.Item>
)

const LbmMessage = ({ message, multiPhone }) => (
  <List.Item style={{ justifyContent: 'end', display: 'flex' }}>
    <Row >
      <Col style={{ display: 'block', justifyContent: 'end', backgroundColor: Color.light_green, padding: '1em', borderRadius: '1em 1em 0 1em' }} span={22}>
        <Content message={message} multiPhone={multiPhone} align='end' />
      </Col>
      <Col style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }} span={2} >
        <span style={{ fontSize: '0.7em', paddingLeft: '0.3em' }}>{t('Admin.SmsPanel.lbm_initial')}</span>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
          <polygon points="0 40, 60 100, 0 100" fill={Color.light_green} />
        </svg>
      </Col>
    </Row>
  </List.Item>
)

export default function SMSPanel ({ owner, ownerType }) {
  const [form] = Form.useForm()
  const [messages, setMessages] = useState([])
  const [incomingMessage, setIncomingMessage] = useState(null)
  const [selectedPhone, setSelectedPhone] = useState(null)
  const [disableSend, setDisableSend] = useState(false)
  const [emptyText, setEmptyText] = useState(true)

  const multiPhone = owner.phones?.length > 1

  useEffect(() => {
    if (!owner?.id) { return }
    getMessages()
    if (owner.phones?.length === 1) { setSelectedPhone(owner.phones[0].value) }
    if (owner.phones?.length > 1) { setDisableSend(true) }
  }, [owner])

  useEffect(() => {
    consumer.subscriptions.create({ channel: 'NotifyChannel' }, {
      connected: () => { console.log('connected NotifyChannel') },
      disconnected: () => { },
      received: (data) => {
        if (data.entity_type === 'message') { setIncomingMessage(data.entity) }
      }
    })
  }, [])

  useEffect(() => {
    if (incomingMessage) {
      receivedMessage(incomingMessage)
      setIncomingMessage(null)
    }
  }, [incomingMessage])

  const receivedMessage = (message) => {
    const index = messages.findIndex((msg) => (msg.id === message.id))
    if (index >= 0) {
      setMessages([
        ...messages.slice(0, index),
        message,
        ...messages.slice(index + 1, messages.length)
      ])
    } else {
      setMessages([...messages, message])
    }
  }

  const getMessages = async () => {
    await get(`admin/${ownerType}/${owner.id}/messages`, {}, (json) => {
      setMessages(json.messages)
    })
  }

  const sendMessage = async (values) => {
    const phone = selectedPhone || values.selected_phone
    await post(`admin/${ownerType}/${owner.id}/messages`,
      { content: values.message, selected_phone: phone },
      (_) => {
        form.setFieldValue('message', '')
      }
    )
  }

  const isAdmin = (message) => (!!message.user_id)

  return (
    <>
      <List
        header={<h3 style={{ textAlign: 'center' }}>SMS</h3>}
        bordered
        rowKey={(record) => record?.id}
        style={{ maxHeight: '45em', overflowY: 'scroll' }}
        dataSource={messages}
        renderItem={message => (
          <>
            {isAdmin(message) && <LbmMessage message={message} multiPhone={multiPhone} />}
            {!isAdmin(message) && <DistantMessage message={message} />}
          </>
        )
        }
      />
      < Form style={{ paddingTop: '1em' }} layout="vertical" name="chatForm" onFinish={sendMessage} form={form} >
        <Form.Item name="message">
          <TextArea rows={3} placeholder="Votre SMS" onChange={(e) => { setEmptyText(e.target.value.length < 3) }} />
        </Form.Item>
        {owner.phones?.length > 1 &&
          <Form.Item name="selected_phone">
            <Select
              options={owner.phones.map((o) => ({ label: `${o.description} : ${o.value}`, value: o.value }))}
              placeholder={t('Admin.SmsPanel.select_phone')}
              onChange={() => (setDisableSend(false))}
            />
          </Form.Item>
        }
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={disableSend || emptyText}>
            Envoyer
          </Button>
        </Form.Item>
      </Form >
    </>
  )
}
