import { Layout, Modal, Spin } from 'antd'
import { t } from 'i18next'
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import SlotForm from '../SlotForm'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import AvailableNowCard from '../AvailableNowCard'
import CreateEventCard from '../../EventsPage/CreateEventCard'
import CreatePeriodCard from '../../PeriodPage/CreatePeriodCard'

dayjs.extend(weekday)
dayjs.extend(localeData)

export default function BoxCalendarPageXL ({
  box, slotModalVisible, loading, slotForm, setSlotModalVisible,
  onAdd, rangeDisable, boxAvailableNow, setRangeDisable, days,
  loadCalendarData, day, businessHours, events, renderEventContent, selected, minHour, maxHour
}) {
  return (
    <>
      {box && <>
        <Modal
          open={slotModalVisible}
          width="800px"
          title={t('Admin.BoxCalendar.popupTitle')}
          okText={t('actions.validate')}
          confirmLoading={loading}
          onOk={slotForm.submit}
          onCancel={() => setSlotModalVisible(false)}
          >
          <Spin spinning={loading}>
            <SlotForm form={slotForm} onFinish={onAdd} rangeDisable={rangeDisable} />
          </Spin>
        </Modal>
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          <div style={{ display: 'flex' }}>
            {
              boxAvailableNow && <AvailableNowCard setModalVisible={setSlotModalVisible} setRangeDisable={setRangeDisable} form={slotForm} />
            }
            <CreateEventCard box={box}/>
            <CreatePeriodCard days={days} loadCalendarData={loadCalendarData} />
          </div>
          {
            box.id && <FullCalendar
              height="auto"
              selectable={true}
              plugins={[timeGridPlugin, interactionPlugin]}
              firstDay={dayjs().day() - 1}
              initialDate={day || dayjs().format('YYYY-MM-DD')}
              initialView="timeGridWeek"
              slotDuration="01:00:00"
              locale={frLocale}
              allDaySlot={false}
              displayEventTime={false}
              businessHours={businessHours(days)}
              events={events}
              eventContent={renderEventContent}
              datesSet={loadCalendarData}
              select={(info) => selected(info)}
              slotMinTime={minHour + ':00:00'}
              slotMaxTime={maxHour + ':00:00'}
            />
          }
        </Layout>
      </>
      }
    </>
  )
}
