import React from 'react'
import { t } from 'i18next'
import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'
import { DisplayContact } from '../IntervenerPage/IntervenerCommon'

export default function IntervenerCard ({ intervener }) {
  const title = (<div style={{ padding: '1em 0' }}>
    <div>{intervener.name}</div>
    <div>{t(`Admin.IntervenersList.table.job.${intervener.job}`)}</div>
  </div>)
  return (
    <Card title={title} bordered={true}
      actions={[
        <Link key='edit' to={`/admin/interveners/${intervener.id}?from=list`}><Button>Edition</Button></Link>
      ]}>
      <div >
        <span>{intervener.boxes?.map((box) => box.name).join(' - ') || 'Aucune box'}</span>
        < DisplayContact fields={(intervener?.phones.map(e => ({ ...e, key: e.id })))} />
        < DisplayContact fields={(intervener?.emails.map(e => ({ ...e, key: e.id })))} />
      </div>
    </Card>
  )
}
