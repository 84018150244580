import { Col, Layout, Row, Spin } from 'antd'
import React from 'react'
import LogsPanel from './LogsPanel'
import CreateEventCard from '../EventsPage/CreateEventCard'
import AdminSlotForm from './AdminSlotForm'
import SMSPanel from '../SMSPanel/SMSPanel'

export default function AdminSlotPage ({ slot, loading, setLoading, cancel, back }) {
  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white', marginTop: '2em', justifyContent: 'space-between' }}>
      <Spin spinning={loading}>
        <Row>
          <Col span={10} >
            <AdminSlotForm slot={slot} setLoading={setLoading} cancel={cancel} back={back} />
          </Col>
          <Col span={5} style={{ margin: '0 1em 0 1em' }}>
            <LogsPanel slot={slot} />
          </Col>
          <Col span={5} style={{ margin: '0 1em 0 1em' }}>
            <SMSPanel owner={slot} ownerType="slots" setLoading={setLoading} />
          </Col>
          <Col span={3}>
            <CreateEventCard box={{ id: slot.box_id }} slot={slot} setLoading={setLoading} cancel={cancel} back={back} />
          </Col>
        </Row>
      </Spin>
    </Layout >
  )
}
