import React from 'react'
import { Col, Row } from 'antd'
import SlotCard from './SlotCard'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const Cards = ({ slots }) => {
  const screens = useBreakpoint()
  const span = screens.xs ? 24 : 6
  const result = []
  slots.forEach((slot) => {
    result.push(<Col span={span} key={slot.id}><SlotCard slot={slot} /></Col>)
  })
  return result
}

export default function SlotCards ({ slots }) {
  const pad = { xs: 8, sm: 16, md: 24, lg: 32 }
  return (
    <Row gutter={[pad, pad]} justify="center">
      <Cards slots={slots} />
    </Row>
  )
}
