import { Button, Layout, Modal, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import BoxFilter from './BoxFilter'
import BoxList from './BoxList'
import { PlusOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { get, postMultipart } from '../../Common/api'
import { Color } from '../../Common/Color'
import consumer from '../../Common/cable'
import BoxCards from './BoxCards'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import BoxCreationForm from './BoxCreationForm'

const LEVELS = {
  empty_clean: 'operation',
  busy: 'operation',
  empty_to_clean: 'operation',
  cleaning: 'operation',
  maintenance: 'maintenance',
  empty_open_err: 'error',
  empty_busy_err: 'error',
  empty_no_closed_err: 'error',
  empty_busy_2_err: 'error',
  busy_open_err: 'error',
  busy_calm_err: 'error',
  to_clean_open_err: 'error',
  cleaning_no_watt_err: 'error',
  cleaning_move_err: 'error',
  stopped: 'stopped',
  unknow: 'unknow'
}

export default function BoxesPage () {
  const [boxes, setBoxes] = useState([])
  const [box, setBox] = useState({})
  const [owners, setOwners] = useState([])
  const [filter, setFilter] = useState({ name: '', zip_code: '', owner_id: '', level: 'all', online: 'all' })
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = useForm()

  const screens = useBreakpoint()

  useEffect(() => {
    getBoxes()
    componentDidMount()
  }, [])

  const updateBoxes = (updatedBox) => {
    if (updatedBox?.id) {
      if (boxes.find((e) => e.id === updatedBox.id)) {
        const newAlert = (updatedBox.state.indexOf('_err') !== -1)
        setBoxes(boxes.map((b) => (b.id === updatedBox.id) ? { ...b, ...updatedBox, newAlert } : b))
      }
    }
  }

  useEffect(() => {
    updateBoxes(box)
  }, [box])

  const formatBoxes = (boxes) => {
    return boxes.map((box) => {
      return {
        ...box,
        level: LEVELS[box.state]
      }
    })
  }

  const getBoxes = async () => {
    setLoading(true)
    await get('admin/boxes', {}, (res) => {
      setLoading(false)
      setBoxes(formatBoxes(res.boxes))
      setOwners(res.owners)
    }, () => {
      setLoading(false)
    })
  }

  const postBox = async (params) => {
    setLoading(true)
    await postMultipart('admin/boxes', params, () => {
      setModalVisible(false)
      setLoading(false)
      getBoxes()
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  const onChangeFilter = (filter) => {
    setFilter(filter)
  }

  const filteredBoxes = boxes.filter((box) => {
    return box.name.toLowerCase().includes(filter.name.toLowerCase()) &&
      box.zip_code.includes(filter.zip_code) &&
      box.owner_id.includes(filter.owner_id) &&
      (filter.level === 'all' || box.level.includes(filter.level)) &&
      (filter.online === 'all' || (box.is_online === filter.online))
  })

  const openAddModal = () => {
    form.resetFields()
    form.setFieldValue('active', false)
    setModalVisible(true)
  }

  const onAdd = async (values) => {
    await postBox(values)
  }

  const componentDidMount = () => {
    consumer.subscriptions.create({ channel: 'BoxesChannel' }, {
      connected: () => { console.log('connected BoxesChannel') },
      disconnected: () => { setBoxes([]) },
      received: (data) => { setBox(data.box) }
    })
  }

  return (
    <Layout>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{boxes.length}</b> {t('Admin.BoxesPage.title')}</div>
          <BoxFilter owners={owners} filter={filter} onChangeFilter={onChangeFilter} />
          <Button onClick={openAddModal} type="primary" style={{ marginLeft: 'auto' }} shape="circle" icon={<PlusOutlined />} />
        </div>
      </Layout>
      <Spin spinning={loading}>
      {!screens.xs && <BoxList boxes={filteredBoxes} />}
      {screens.xs && <BoxCards boxes={filteredBoxes} />}
        {modalVisible && <Modal
          open={modalVisible}
          width="1000px"
          title={t('Admin.BoxesPage.addBoxTitle')}
          okText={t('actions.validate')}
          onOk={form.submit}
          onCancel={() => setModalVisible(false)}
          confirmLoading={loading}
        >
          <Spin spinning={loading}>
            <BoxCreationForm form={form} onFinish={onAdd} owners={owners} />
          </Spin>
        </Modal>}
      </Spin>
    </Layout>
  )
}
