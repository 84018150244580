import React, { useEffect, useState } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import IntervenerCard from './IntervenerCard'
import IntervenerPage from './IntervenerPage'
import { useParams } from 'react-router'
import { get } from '../../Common/api'
export default function Intervener () {
  const { id } = useParams()
  const [intervener, setIntervener] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getIntervener()
    setLoading(false)
  }, [])

  const screens = useBreakpoint()

  const getIntervener = async () => {
    await get(`admin/interveners/${id}`, {}, (json) => {
      setIntervener({ ...json, phones: (json.phones.map(phone => ({ ...phone, value: `+${phone.value}` }))) })
    })
  }

  return (
    <>
      {screens.xs && <IntervenerCard intervener={intervener} loading={loading} setLoading={setLoading} />}
      {!screens.xs && <IntervenerPage intervener={intervener} loading={loading} setLoading={setLoading} />}
    </>
  )
}
