import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { get } from '../../Common/api'

import SlotLayout from '../Slot/SlotLayout'
import BoxSlotList from './BoxSlotList/BoxSlotList'
import ErrorPage from '../ErrorPage/ErrorPage'

export default function BoxPage () {
  const { id } = useParams()
  const { t } = useTranslation()
  const [box, setBox] = useState(null)

  useEffect(() => {
    get(`public/boxes/${id}`, {}, (json) => {
      setBox(json)
    })
  }, [id])

  return (
    <>
      {box && box.active &&
        <SlotLayout box={box} title={t('Public.BoxPage.title')}>
          <BoxSlotList box={box} />
        </SlotLayout>
      }
      {box && !box?.active &&
        <ErrorPage error="box_inactive" name={`${box.name} - ${box.zip_code}`} />
      }
    </>
  )
}
