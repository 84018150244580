import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button, Col, Popover, Row, Tag } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { SLOT_COLORS } from '../../Common/Color'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Info from '../../Common/Info'

const columns = (screens) => [
  {
    title: t('Admin.SlotList.table.name'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: <Info text={t('Admin.SlotList.table.bookedSlots')} infoText={t('Admin.SlotList.table.infoBookedSlots')} />,
    dataIndex: 'booked_slots',
    key: 'booked_slots',
    width: 200,
    render: (_, o) => bookedSlotsRender(o.booked_slots)
  },
  {
    title: t('Admin.SlotList.table.box'),
    dataIndex: 'box.name',
    key: 'box.name',
    render: (_, o) => `${o.box.name} - ${o.box.interphone}`,
    width: 200,
    sorter: (a, b) => a.box.name.localeCompare(b.box.name)
  },
  {
    width: 120,
    title: t('Admin.SlotList.table.slotType'),
    dataIndex: 'slot_type',
    key: 'slot_type',
    render: (_, o) => <Tag color={SLOT_COLORS[o.slot_type]}>{t(`Admin.Slot.slotType.${o.slot_type}`)}</Tag>,
    sorter: (a, b) => a?.slot_type.localeCompare(b?.slot_type),
    hidden: !screens.md
  },
  {
    width: 100,
    title: t('Admin.SlotList.table.range'),
    dataIndex: 'range',
    key: 'range',
    render: (_, o) => `${dayjs(o?.begin_date).format('H[h]mm')}-${dayjs(o?.end_date).format('H[h]mm')}`,
    sorter: (a, b) => dayjs(a.begin_date) - dayjs(b.begin_date)
  },
  {
    width: 150,
    title: t('Admin.SlotList.table.phone'),
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => `+${phone}`,
    sorter: (a, b) => a.phone - b.phone
  },
  {
    width: 150,
    title: t('Admin.SlotList.table.state'),
    dataIndex: 'state',
    key: 'state',
    render: (_, o) => <Tag color={t(`Admin.Slot.stateColor.${o.state}`)}>{t(`Admin.Slot.state.${o.state}`)}</Tag>,
    sorter: (a, b) => a?.state.localeCompare(b?.state)
  },
  {
    width: 100,
    title: t('Admin.SlotList.table.digicode'),
    dataIndex: 'digicode',
    key: 'digicode',
    sorter: (a, b) => a?.digicode.localeCompare(b?.digicode),
    hidden: !screens.md
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/slots/${o.id}?from=list`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
].filter((col) => !col.hidden)

const bookedSlotsRender = (bookedSlots) => {
  if (bookedSlots.slots) {
    return <Popover content={popOverContent(bookedSlots.slots)} title={t('Admin.SlotList.table.previousSlots')}>
      <span style={{ width: '100%', display: 'block' }}>
        {bookedSlots.count}
      </span>
    </Popover>
  } else {
    return bookedSlots.count
  }
}

const popOverContent = (slots) => {
  return slots.map(slot => (
    <Row key={slot.id} style={{ width: '30em' }}>
      <Col span={8}>{slot.box}</Col>
      <Col span={8}>{dayjs(slot.date).format('DD/MM/YYYY')}</Col>
      <Col span={8}><Tag color={t(`Admin.Slot.stateColor.${slot.state}`)}>{t(`Admin.Slot.state.${slot.state}`)}</Tag></Col>
    </Row>
  ))
}

export default function SlotList ({ slots }) {
  const screens = useBreakpoint()
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns(screens)}
      dataSource={slots}
    />
  )
}
