import React, { useState } from 'react'
import { t } from 'i18next'
import { Button, Col, Form, Input, List, Radio, Row, Select, Spin, Tag } from 'antd'
import { Outlet } from 'react-router'
import { put } from '../../Common/api'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import _ from 'lodash'

export const GepetoInfo = ({ gepeto }) => (
  <div>
    <div>{t('Admin.GepetoList.table.ip')}: {gepeto.ip}</div>
    <div>{t('Admin.GepetoList.table.latency')}: {gepeto.latency}ms</div>
    <div>{t('Admin.GepetoList.table.command')}: {gepeto.command}</div>
    <div>{t('Admin.GepetoList.table.box_state')}: {t(`Admin.Box.state.${gepeto.box.state}`)}</div>
    <div>{t('Admin.GepetoList.table.gepeto_state')}: {gepeto.data?.state}</div>
  </div>
)

const DisplayHash = ({ hash, mainKey }) => {
  return Object.keys(hash).map(key => (
    <div key={key} style={{ whiteSpace: 'pre-line' }}>{t(`Admin.${mainKey}.${key}`) + ': ' + hash[key]}</div>
  ))
}

export const DisplayData = ({ gepeto }) => (<DisplayHash hash={gepeto.data || {}} mainKey="GepetoData" />)

export const DisplayVersion = ({ gepeto }) => (<DisplayHash hash={gepeto.version || {}} mainKey="GepetoVersion" />)

export const Commands = ({ gepeto, setGepeto }) => {
  const [loading, setLoading] = useState(false)
  const sendCommand = async (command) => {
    setLoading(true)
    await put(`admin/gepetos/${gepeto.id}`, command, (json) => {
      setGepeto(json)
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  const screens = useBreakpoint()
  const span = screens.xs ? '24' : '12'
  const result = []
  const commands = ['empty_clean', 'busy', 'empty_to_clean', 'cleaning']
  commands.forEach((command) => {
    result.push(
      <Col key={command} span={span}>
        <Button style={{ margin: '1em' }} disabled={loading || gepeto.command} key={command} onClick={() => { sendCommand({ command }) }}>
          {t(`Admin.GepetoList.command.${command}`)}
        </Button>
      </Col>
    )
  })
  return (
    <>
      <Spin spinning={loading}> <Outlet /> </Spin>
      <Row>{result}</Row>
    </>)
}

export const Digicode = ({ gepeto, setGepeto }) => {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const defaultAvailableKeys = gepeto.available_keys.length === 0 ? 'all' : 'partial'
  const [displayAvailableKeys, setDisplayAvailableKeys] = useState(defaultAvailableKeys === 'partial')

  const defaultValues = {
    available_keys: gepeto.available_keys.toString().replaceAll(',', ''),
    end_key: gepeto.end_key,
    displayAvailableKeys: defaultAvailableKeys
  }
  const options = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '#', value: '#' },
    { label: '*', value: '*' }
  ]

  const updateDigicode = async (values) => {
    if (!values.available_keys) {
      values.available_keys = ''
    }
    await put(`admin/gepetos/${gepeto.id}`, values,
      (json) => {
        setGepeto(json)
        setLoading(false)
      }, () => {
        setLoading(false)
      }
    )
  }

  const updateDisplayAvailableKeys = (visible) => {
    setDisplayAvailableKeys(visible)
    if (visible) {
      form.setFieldValue('available_keys', defaultValues.available_keys)
    } else {
      form.setFieldValue('available_keys', '')
    }
  }

  return (
    <Spin spinning={loading}>
      <Form form={form} initialValues={defaultValues} onFinish={updateDigicode}>
        <Form.Item name='displayAvailableKeys'>
          <Radio.Group>
            <Radio value='all' onClick={() => updateDisplayAvailableKeys(false)} >{t('Admin.GepetoPage.allKeysAvailable')}</Radio>
            <Radio value='partial' onClick={() => updateDisplayAvailableKeys(true)} >{t('Admin.GepetoPage.groupKeysAvailable')}</Radio>
          </Radio.Group>
        </Form.Item>
        {displayAvailableKeys &&
          <Form.Item name='available_keys' label={t('Admin.GepetoPage.availableKeys')}
            extra={<div>{t('Admin.GepetoPage.acceptedKeys')}</div>}>
            <Input style={{ maxWidth: '20em' }} />
          </Form.Item>
        }
        <Form.Item name='end_key' label={t('Admin.GepetoPage.endKey')}>
          <Select options={options} style={{ maxWidth: '20em' }} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>{t('actions.save')}</Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export const PingLog = ({ latencies, title }) => {
  return (
    <List
      header={title}
      bordered
      style={{ maxHeight: '45em', overflowY: 'scroll' }}
      rowKey={(record) => record?.id}
      dataSource={_.orderBy(latencies, ['created_at'], ['desc'])}
      renderItem={(latency) => (
        <List.Item>
          <Tag color='grey'>
            <span style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              {dayjs(latency.created_at).format('HH:mm:ss')}
            </span>
          </Tag>
          <p style={{ whiteSpace: 'pre-line' }}>
            {latency.content.replaceAll('|', '\n')}
          </p>
        </List.Item>
      )}
    />
  )
}
