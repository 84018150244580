import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { FormOutlined } from '@ant-design/icons'

const columns = () => [
  {
    title: t('Admin.GepetoList.table.name'),
    dataIndex: 'name',
    key: 'name',
    width: 200,
    render: (_, o) => o.box.name,
    sorter: (a, b) => a.box.name.localeCompare(b.box.name)
  },
  {
    title: t('Admin.GepetoList.table.zipCode'),
    dataIndex: 'zip_code',
    key: 'zipCode',
    width: 120,
    render: (_, o) => o.box.zip_code,
    sorter: (a, b) => a.box.zip_code - b.box.zip_code
  },
  {
    title: t('Admin.GepetoList.table.latency'),
    dataIndex: 'latency',
    key: 'latency',
    width: 120,
    render: (_, o) => o.latency,
    sorter: (a, b) => a.latency - b.latency
  },
  {
    title: t('Admin.GepetoList.table.version'),
    dataIndex: 'version',
    key: 'version',
    width: 120,
    render: (version) => version.gepeto
  },
  {
    title: t('Admin.GepetoList.table.ip'),
    dataIndex: 'ip',
    key: 'ip',
    width: 120,
    render: (_, o) => o.ip,
    sorter: (a, b) => a.ip - b.ip
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/gepetos/${o.id}`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
]
export default function GepetoList ({ gepetos }) {
  const rowClassName = (gepeto) => {
    return gepeto.newAlert ? 'new-alert' : ''
  }
  return (
    <CustTable
      rowClassName={rowClassName}
      height={'calc(100vh - 230px)'}
      initColumns={columns()}
      dataSource={gepetos}
    />
  )
}
