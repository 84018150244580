import { Form, Input } from 'antd'
import React from 'react'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'

export default function OwnerForm ({ form, onFinish }) {
  const onSubmit = (values) => {
    onFinish(values)
  }

  return (
    <Form
      id="ownerForm"
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onSubmit}
    >
      <Form.Item name="name" label={t('Admin.OwnerForm.name')}
        rules={[{ required: true, message: t('rules.required') }]}>
        <Input />
      </Form.Item>
      { form.getFieldValue('digicode_cleanup') && <>
          <div style={{ marginBottom: '8px' }}>{t('Admin.OwnerForm.digicode_cleanup')}</div>
          <div style={{ padding: '4px 11px', border: '#d9d9d9 solid 1px', borderRadius: '16px', minHeight: '2.25em', marginBottom: '1em' }}>
            { form.getFieldValue('digicode_cleanup')}
          </div>
        </>
      }
      <Form.Item name="comment" label={t('Admin.OwnerForm.comment')}>
        <TextArea rows={5} />
      </Form.Item>
    </Form >
  )
}
