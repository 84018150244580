import React from 'react'
import { t } from 'i18next'
import { Input } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export default function SlotFilter ({ filter, onChangeFilter }) {
  const onChange = (hash) => {
    onChangeFilter({ ...filter, ...hash })
  }

  const screens = useBreakpoint()

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      {!screens.xs &&
        <Input
          style={{ width: '150px' }}
          onChange={(e) => { onChange({ name: e.target.value }) }} placeholder={t('Admin.SlotFilter.name')}
          value={filter.name}
        />
      }
      <Input
        style={{ width: '150px' }}
        onChange={(e) => { onChange({ box: e.target.value }) }} placeholder={t('Admin.SlotFilter.box')}
        value={filter.box}
      />
      {!screens.xs &&
        <Input
          style={{ width: '150px' }}
          onChange={(e) => { onChange({ phone: e.target.value }) }} placeholder={t('Admin.SlotFilter.phone')}
          value={filter.phone}
        />
      }
    </div>
  )
}
